import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"
import Comunicaciones from "../../../static/images/icons/web/icon30.svg"

const Soluciones2 = () => {
  const data = useStaticQuery(graphql`
    query Images_comunicaciones {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Comunicaciones seguras Kit Digital | Qualoom</title>
        <meta name="title" content="Comunicaciones seguras Kit Digital | Qualoom"/>
        <meta name="description" content="Soluciones del Kit Digital para mantener la seguridad en las conexiones entre los dispositivos de la empresa."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/kit-digital/comunicaciones-seguras/"/>
        <link rel="canonical" href="https://www.qualoom.es/kit-digital/comunicaciones-seguras/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/kit-digital/comunicaciones-seguras/"/>
        <meta property="og:title" content="Comunicaciones seguras Kit Digital | Qualoom"/>
        <meta property="og:description" content="Soluciones del Kit Digital para mantener la seguridad en las conexiones entre los dispositivos de la empresa."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/kit-digital/comunicaciones-seguras/"/>
        <meta property="twitter:title" content="Comunicaciones seguras Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Soluciones del Kit Digital para mantener la seguridad en las conexiones entre los dispositivos de la empresa."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/kit-digital/comunicaciones-seguras/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Kit Digital", "item": {"@id": "https://www.qualoom.es/kit-digital/", "name": "Kit Digital"}},
              {"@type":"ListItem", "position":"3", "name":"Comunicaciones seguras", "item": {"@id": "https://www.qualoom.es/kit-digital/comunicaciones-seguras/", "name": "Comunicaciones seguras"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Comunicaciones seguras</h1>
              <p>Soluciones para mantener la seguridad en las conexiones entre los dispositivos de la empresa</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Comunicaciones seguras</h2>
          <div className="soluciones-info">
            <img src={Comunicaciones} alt="Comunicaciones seguras"></img>
            <p><b>Objetivo principal:</b> Proporcionar a las empresas beneficiarias seguridad en las conexiones entre losdispositivos de sus empleados y la empresa.</p>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Servicios ofrecidos</p></div>
            <div className="row">
              <div className="normal-cell-1">
                <p><b>SSL:</b> dispondrás de un protocolo de capa de sockets seguros, para crear una conexión segura y cifrada.</p>
                <p><b>Cifrado de extremo a extremo:</b> tendrás las comunicaciones cifradas en todo su recorrido, con el objetivo de prevenir ataques.</p>
                <p><b>Logs de conexión:</b> serás capaz de mantener un registro de los dispositivos que se han conectado a tu red privada.</p>
                <p><b>Control de acceso:</b> tendrás la posibilidad de permitir la conexión a tu red privada única y exclusivamente a los dispositivos autorizados por ti.</p>
                <p><b>Dispositivos móviles:</b> podrás hacer uso de esta solución desde dispositivos móviles.</p>
                <p><b>Configuración inicial y actualizaciones de seguridad:</b> dispondrás de una configuración inicial para que puedas usarlo correctamente, con las respectivas actualizaciones de firmas de malware y otros datos para detección de amenazas además de las actualizaciones de software de seguridad periódicas requeridas.</p>
              </div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Porcentajes de ejecución por fases</p></div>
            <div className="row">
              <div className="normal-cell-2"><p>Primera fase 70%</p></div>
              <div className="normal-cell-2"><p>Segunda fase 30%</p></div>
            </div>   
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Importe máximo del Bono por segmento</p></div>
            <div className="row">
              <div className="normal-cell-1 text-center">125€ / usuario</div>
              <div className="normal-cell-3"><p>Segmento I<br/>Hasta 48 usuarios</p></div>
              <div className="normal-cell-3"><p>Segmento II<br/>Hasta 9 usuarios</p></div>
              <div className="normal-cell-3"><p>Segmento III<br/>Hasta 2 usuarios</p></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Soluciones2

